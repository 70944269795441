* {
    font-family: 'Electrolize', sans-serif;
}

body,
html {
    overflow-x: hidden;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

button.dropdown-item:focus {
    background-color: #f2b632;
}

button.dropdown-item:active {
    background-color: #f2b632;
}

input.form-control:focus,
textarea.form-control:focus {
    outline: none !important;
    border: 2px solid#f2b632 !important;
    box-shadow: none;
}

.mr-1 {
    margin-right: 2.5px;
}

.mr-2 {
    margin-right: 5px;
}

.mr-3 {
    margin-right: 7.5px;
}

.mr-4 {
    margin-right: 10px;
}

.mr-5 {
    margin-right: 12.5px;
}

.ml-1 {
    margin-right: -2.5px;
}

.ml-2 {
    margin-right: -5px;
}

.ml-3 {
    margin-right: -7.5px;
}

.ml-4 {
    margin-right: -10px;
}

.ml-5 {
    margin-right: -12.5px;
}

.custom-vh {
    height: calc(100vh - 200px);
}

.form-check-input:checked {
    background-color: #f2b632 !important;
    border-color: black;
}

.form-check-input:focus {
    background-color: white;
    border-color: black;
    color: black;
}

.form-select:focus {
    border-color: #f2b632 !important;
    box-shadow: inset 0 1px 1px #f2b632, 0 0 8px #f2b632 !important;
}

.nav-link {
    color: black;
    transition: transform 0.2s;
}

.nav-link:hover {
    transform: scale(1.05);
    color: black;
    cursor: pointer;
}

.hidden {
    visibility: hidden;
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0px rgba(242, 182, 50, 1);
    }

    100% {
        box-shadow: 0 0 0 30px rgba(242, 182, 50, 0);
    }
}

.pulse {
    border-radius: 50%;
    animation: shadow-pulse 2s infinite;
}

.carousel-caption {
    background-color: rgba(0, 0, 0, 0.599);
    border-radius: 5px;
}

.carousel-caption h3 {
    color: white;
}

.carousel-caption p {
    display: none;
}
